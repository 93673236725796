export default {
  switchLanguage: "Zmień język",
  languages: {
    polish: "Polski",
    english: "Angielski",
    ukrainian: "Ukraiński",
  },
  loading: "Ładowanie...",
  error: "Wystąpił błąd",
  buttons: {
    close: "Zamknij",
    cancel: "Anuluj",
    continue: "Kontynuuj",
    back: "Wstecz",
    today: "Dziś",
    thisWeek: "Ten tydzień",
    clearFilters: "Wyczyść filtry",
    confirm: "Potwierdź",
    reserve: "Zarezerwuj",
    save: "Zapisz",
  },
  fields: {
    firstName: "Imię",
    lastName: "Nazwisko",
    email: "Email",
    phone: "Telefon",
    password: "Hasło",
  },
  price: {
    base: "Cena bazowa",
    total: "Całkowita cena",
    currency: "zł",
  },
  payment: {
    methods: {
      title: "Metoda płatności",
      onSite: "Zapłacę później",
      pass: "Karnet",
      online: "Płatność online",
      blik: "BLIK",
      transfer: "Przelew",
      paymentInitiation: "Przelew",
      select: "Wybierz metodę płatności",
      509: "BLIK",
      106: "Przelew",
    },
    serviceFee: {
      title: "Opłata serwisowa",
      amount: "1.98 zł",
      tooltip:
        "Opłata pokrywa koszty operacyjne związane z obsługą płatności, w tym prowizje operatorów płatności, koszty administracyjne oraz utrzymanie systemu rezerwacji.",
    },
    status: {
      paid: "Opłacone",
      unpaid: "Nieopłacone",
      pending: "Oczekuje na płatność",
      pending_online: "Oczekuje na płatność online",
    },
    required: "Płatność wymagana",
    timeLimit:
      "Aby potwierdzić rezerwację, dokonaj płatności w ciągu 30 minut. Po tym czasie rezerwacja zostanie automatycznie anulowana.",
    proceedToPayment: "Przejdź do płatności",
    processing: "Przetwarzanie...",
  },
  errors: {
    loadingFailed: "Nie udało się załadować danych. Spróbuj ponownie później.",
    savingFailed: "Nie udało się zapisać zmian. Spróbuj ponownie później.",
    generalError: "Wystąpił błąd. Spróbuj ponownie później.",
  },
  status: {
    pending: "Oczekuje",
    accepted: "Zatwierdzony",
  },
  venue: {
    select: "Wybierz obiekt",
    all: "Wszystkie obiekty",
    address: "Adres obiektu",
  },
  search: {
    placeholder: "Szukaj...",
    byClient: "Szukaj po kliencie...",
    noResults: "Brak wyników",
    filters: "Filtry",
  },
  date: {
    from: "Od",
    to: "Do",
  },
  time: {
    from: "Od",
    to: "Do",
  },
  weekDays: {
    0: "Niedziela",
    1: "Poniedziałek",
    2: "Wtorek",
    3: "Środa",
    4: "Czwartek",
    5: "Piątek",
    6: "Sobota",
  },
  form: {
    required: "To pole jest wymagane",
    invalid: "Nieprawidłowa wartość",
    save: {
      button: "Zapisz",
      loading: "Zapisywanie...",
      success: "Zapisano pomyślnie",
      error: "Błąd podczas zapisywania",
    },
  },
  legal: {
    terms: "regulamin",
    privacy: "polityka prywatności",
    gdpr: "RODO",
    company: "Playmore",
    copyright: "© {year} Playmore. Wszystkie prawa zastrzeżone.",
  },
};
