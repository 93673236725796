export default {
  calendar: {
    addToGoogle: "Dodaj do Google Calendar",
    addToICal: "Dodaj do iCal",
  },
  passes: {
    details: {
      entries: "wejść",
      remaining: "Pozostało",
      value: "Wartość",
      validUntil: "Ważny do",
      validity: "Ważność",
      days: "dni",
      type: "Typ",
      types: {
        amount: "Wartościowy",
        value: "Ilościowy",
      },
      duration: "Czas trwania",
      price: "Cena",
      availableHours: "Dostępne godziny",
    },
  },
  menu: {
    courts: {
      label: "Korty",
      icon: "🎾",
    },
    classes: {
      label: "Zajęcia",
      icon: "👥",
    },
    trainers: {
      label: "Trenerzy",
      icon: "👨‍🏫",
    },
    passes: {
      label: "Karnety",
      icon: "🎫",
    },
  },
  meta: {
    courts: {
      title: "Rezerwacja kortów | Playmore",
      description:
        "Zarezerwuj kort tenisowy, squashowy lub badmintonowy. Sprawdź dostępność i ceny w obiektach sportowych w Twojej okolicy.",
    },
    classes: {
      title: "Zajęcia fitness | Playmore",
      description:
        "Zapisz się na zajęcia fitness, jogę, pilates i inne zajęcia grupowe. Sprawdź grafik i dostępność miejsc.",
    },
    trainers: {
      title: "Profil trenera | Playmore",
      description:
        "Zarządzaj swoim profilem trenera, dostępnością i rezerwacjami. Sprawdź nadchodzące zajęcia i treningi.",
    },
  },
  common: {
    loading: {
      classes: "Ładowanie zajęć...",
      trainers: "Ładowanie trenerów...",
      passes: "Ładowanie karnetów...",
      data: "Ładowanie danych...",
    },
    errors: {
      fetchClasses: "Nie udało się pobrać listy zajęć",
      fetchTrainers: "Nie udało się pobrać listy trenerów",
      fetchPasses: "Nie udało się pobrać listy karnetów",
      fetchVenue: "Nie udało się pobrać danych obiektu",
      availability: "Nie udało się sprawdzić dostępności",
      createNotification: "Nie udało się utworzyć powiadomienia",
      noCourts: "Brak dostępnych kortów w wybranym terminie",
    },
    filters: {
      allTrainers: "Wszyscy trenerzy",
      allClasses: "Wszystkie zajęcia",
      allVenueSportObjects: "Wybierz obiekt",
    },
    notifications: {
      notify: "Powiadom mnie",
      willNotify: "Powiadomimy Cię",
      modal: {
        createTitle: "Utworzyć powiadomienie?",
        createMessage:
          "Czy chcesz otrzymać powiadomienie, gdy ten termin będzie dostępny?",
        successTitle: "Powiadomienie zostało utworzone!",
        successMessage: "Powiadomimy Cię, gdy ten termin będzie dostępny.",
      },
    },
  },
  classes: {
    header: {
      trainerTBA: "Trener zostanie ogłoszony",
    },
    tile: {
      duration: "min",
      participants: {
        occupied: "zajęte",
      },
    },
    payment: {
      required: "Płatność wymagana",
      timeLimit:
        "Aby potwierdzić rezerwację, dokonaj płatności w ciągu 30 minut. Po tym czasie rezerwacja zostanie automatycznie anulowana.",
      methods: {
        title: "Metoda płatności",
        onSite: "Płatność na miejscu",
        carnet: "Karnet",
        online: "Płatność online",
      },
      online: {
        title: "Wybierz metodę płatności online",
        blik: "BLIK",
        transfer: "Przelew",
        paymentInitiation: "Przelew",
        106: "Przelew",
        509: "BLIK",
      },
    },
    carnets: {
      title: "Twoje karnety",
      remaining: "Pozostało",
      entries: "wejść",
      amount: "zł",
      validUntil: "Ważny do",
    },
    partnerCard: {
      question: "Czy posiadasz kartę partnerską?",
      yes: "Tak",
      no: "Nie",
    },
    price: {
      base: "Cena bazowa",
      partnerCard: "Karta partnerska",
      serviceFee: {
        title: "Opłata serwisowa",
        tooltip:
          "Opłata pokrywa koszty operacyjne związane z obsługą płatności, w tym prowizje operatorów płatności, koszty administracyjne oraz utrzymanie systemu rezerwacji.",
      },
      total: "Całkowita cena",
    },
    buttons: {
      payment: "Przejdź do płatności",
      close: "Zamknij",
      cancel: "Anuluj",
      reserve: "Zarezerwuj",
    },
    loading: "Ładowanie zajęć...",
  },
  courts: {
    steps: {
      sport: {
        title: "Wybierz sport",
        number: "1",
      },
      court: {
        title: "Wybierz typ kortu",
        number: "2",
        select: "Wybierz kort",
      },
    },
    duration: {
      label: "Długość rezerwacji",
      options: {
        60: "60 minut",
        90: "90 minut",
        120: "120 minut",
      },
    },
    timezone: "Wszystkie godziny są wyświetlane w strefie czasowej {timezone}",
    priceList: {
      title: "Wybierz cennik",
    },
    partnerCard: {
      title: "Liczba kart zniżkowych",
      discount: "Karty zniżkowe",
    },
  },
  passes: {
    details: {
      validity: "Ważność",
      days: "dni",
      value: "Wartość",
      type: "Typ",
      types: {
        amount: "Wartościowy",
        value: "Ilościowy",
        entries: "wejść",
      },
      entries: "wejść",
      remaining: "Pozostało",
      duration: "Czas trwania",
      price: "Cena",
      availableHours: "Dostępne godziny",
    },
    actions: {
      buy: "Kup karnet",
    },
    empty: "Brak dostępnych karnetów",
    legal: {
      text: "Składając zamówienie, Użytkownik oświadcza, iż zapoznał się i akceptuje postanowienia regulaminu oraz polityki prywatności wybranego obiektu sportowego, a także",
      terms: "regulamin świadczenia usług Playmore",
      privacy: "politykę prywatności",
      and: "oraz",
    },
  },
  trainers: {
    details: {
      availability: "Dostępność",
    },
  },
  details: {
    door: {
      title: "Wejście do obiektu",
      paymentRequired:
        "Aby uzyskać dostęp do obiektu, należy najpierw opłacić rezerwację.",
      openButton: "Otwórz drzwi do obiektu",
      success: "Drzwi zostały otwarte",
      error: "Nie udało się otworzyć drzwi. Spróbuj ponownie.",
    },
  },
};
