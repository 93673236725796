<template>
  <div class="bg-gray-800 text-white p-2 text-xs">
    <div class="flex justify-between items-center">
      <div class="flex space-x-4">
        <div>
          <span class="font-bold">Version:</span>
          {{ appVersion }}
        </div>
        <div>
          <span class="font-bold">Build:</span>
          {{ buildMode }}
        </div>
        <div>
          <span class="font-bold">Env:</span>
          {{ nodeEnv }}
        </div>
        <div>
          <span class="font-bold">Build Time:</span>
          {{ formattedBuildTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

const appVersion = ref(import.meta.env.VITE_APP_VERSION || "0.0.1");

// Computed properties dla zmiennych środowiskowych
const buildMode = computed(
  () => import.meta.env.VITE_DATA_SOURCE || import.meta.env.MODE
);
const nodeEnv = computed(() => process.env.NODE_ENV || import.meta.env.MODE);
const vueVersion = computed(() => import.meta.env.VITE_APP_VERSION);

const formattedBuildTime = computed(() => {
  // Sprawdzenie środowiska
  const buildTime = import.meta.env.VITE_BUILD_TIME;
  if (!buildTime) return new Date().toLocaleString();

  return new Date(buildTime).toLocaleString();
});
</script>
