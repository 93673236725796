import { createApp } from "vue";
import { useClarity } from "./composables/useClarity";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import { i18n, i18nPlugin } from "./i18n";
import "./assets/tailwind.css";
import "./assets/playmore.css";
import "./assets/reservations.css";
import * as Sentry from "@sentry/vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

// Import wersji z package.json
const version = "1.0.0"; // TODO: Dodać wersję jako zmienną środowiskową VITE_APP_VERSION

const app = createApp(App);

// Dodaj informację o języku do Sentry
const currentLocale = localStorage.getItem("language") || "pl";

Sentry.init({
  app,
  dsn:
    import.meta.env.VITE_SENTRY_DSN ||
    "https://71548dc272be0fa88b1ad7e424521f75@o4508270333788160.ingest.de.sentry.io/4508270335033424",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Release i environment
  release: `playmore-player@${version}`,
  environment: import.meta.env.MODE,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/zephyr\.playmore\.pl/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Dodaj tag języka do wszystkich zdarzeń
  initialScope: {
    tags: {
      language: currentLocale,
    },
  },
  // Aktualizuj tag języka przy każdej zmianie
  beforeSend(event) {
    event.tags = {
      ...event.tags,
      language: i18n.global.locale.value,
    };
    return event;
  },
  // Dodatkowe informacje o release
  beforeBreadcrumb(breadcrumb) {
    return {
      ...breadcrumb,
      data: {
        ...breadcrumb.data,
        release: `playmore-player@${version}`,
        environment: import.meta.env.MODE,
      },
    };
  },
});

// Użyj pluginów
app.use(store);
app.use(router);
app.use(i18n);
app.use(i18nPlugin);

// Rejestracja komponentu v-select globalnie
app.component("v-select", vSelect);

// Dodaj globalny mixin do obsługi tłumaczeń w Options API
app.mixin({
  computed: {
    $t() {
      return this.$i18n.t.bind(this.$i18n);
    },
  },
});

app.mount("#app");

// Initialize Clarity
const { initClarity } = useClarity();
initClarity();
