import { onMounted } from "vue";
import { useRouter } from "vue-router";

export const useClarity = () => {
  const router = useRouter();

  const initClarity = () => {
    const clarityProjectId = import.meta.env.VITE_CLARITY_PROJECT_ID;

    if (clarityProjectId) {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", clarityProjectId);
    }
  };

  const handleRouteChange = (to) => {
    if (window.clarity && typeof window.clarity === "function") {
      window.clarity("set", "page_view", to.fullPath);
    }
  };

  onMounted(() => {
    initClarity();

    // Track route changes
    router.afterEach((to) => {
      handleRouteChange(to);
    });
  });

  return {
    initClarity,
  };
};
