export default {
  title: "Payment Status",
  messages: {
    PENDING: {
      title: "Waiting for your payment",
      description:
        "We are waiting for payment confirmation. Status is being checked automatically. In case of any issues, please check your email.",
    },
    SUCCESS: {
      title: "Thank you for your payment!",
      description: "Your reservation has been confirmed",
    },
    VOIDED: {
      title: "Payment was rejected",
      description:
        "Unfortunately, the payment could not be processed. Please try again",
    },
    NOTCONFIRMED: {
      title: "Payment unconfirmed",
      description:
        "Payment is awaiting confirmation. Please check the status in a moment",
    },
    PARTIALLY_REFUNDED: {
      title: "Payment partially refunded",
      description: "Part of the amount has been returned to your account",
    },
    REFUNDED: {
      title: "Payment refunded",
      description: "The full amount has been returned to your account",
    },
    ABANDONED: {
      title: "Payment not completed",
      description: "Payment time limit has been exceeded",
    },
    CONFIRMED: {
      title: "Payment confirmed",
      description: "Your payment has been confirmed. Thank you!",
    },
  },
  refreshButton: "Refresh status",
  errors: {
    fetch: "Failed to fetch payment status",
    invalidId: "Invalid payment identifier",
    notFound: "Payment with provided identifier not found",
    unauthorized: "Unauthorized to view this payment",
    serverError: "Server error occurred while fetching payment status",
  },
  noPaymentId: "Missing payment ID",
  backToReservation: "Back to reservation details",
};
