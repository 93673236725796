export default [
  {
    path: "/payment/create/:venueId/:reservationId",
    name: "CreatePayment",
    component: () => import("@/views/createPayment/CreatePayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
