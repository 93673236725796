export default (api) => ({
  async getProfile() {
    const response = await api.get("/auth/me");
    return response.data;
  },

  async updateUser(data) {
    const response = await api.patch("/user", { ...data, is_active: true });
    return response.data;
  },

  async updateUserSportSkill(userId, sportData) {
    const response = await api.post(`/user/sport_skill/${userId}`, sportData);
    return response.data;
  },

  async initiateOtpLogin(data) {
    const response = await api.post("/auth/otp_login_signup", data);
    return response.data;
  },

  async verifyOtpToken(code, phoneNumber) {
    const response = await api.post("/auth/otp_token", {
      code,
      phone_number: phoneNumber,
    });
    return response.data;
  },

  async getUserCarnets() {
    const response = await api.get(`/user_venue_carnet`);
    return response.data;
  },

  async getUserReservations(venueId, startTime, endTime) {
    let url = `/reservation/${venueId}`;
    const params = {};
    if (startTime) params.start_time = startTime;
    if (endTime) params.end_time = endTime;
    const response = await api.get(url, { params });
    return response.data;
  },

  async getTrainerReservations(venueId, beginTime, endTime) {
    let url = `/trainer_venue_profile/reservation/${venueId}`;
    const params = {};
    if (beginTime) params.begin_time = beginTime;
    if (endTime) params.end_time = endTime;
    const response = await api.get(url, { params });
    return response.data;
  },

  async deleteUserReservation(reservationId) {
    const response = await api.delete(`/reservation/${reservationId}`);
    return response.data;
  },

  async getPaymentStatus(paymentId) {
    const response = await api.get(`/payment/${paymentId}`);
    return response.data;
  },

  async createTermNotification(
    userVenueProfileId,
    venueSportObjectId,
    tagId,
    startTime,
    endTime,
    venueId
  ) {
    const response = await api.post(`/reservation/term_notification`, {
      user_venue_profile_id: userVenueProfileId,
      venue_sport_object_id: venueSportObjectId,
      tag_id: tagId,
      start_time: startTime,
      end_time: endTime,
      venue_id: venueId,
    });
    return response.data;
  },

  async createReservation(
    venueId,
    venueSportObjectId,
    startTime,
    endTime,
    discountCards,
    priceListId,
    appPaymentMethodsId,
    reservation_calculated_price,
    phoneNumber,
    firstName,
    lastName,
    eMail,
    user_venue_carnet_id,
    online_payment_method_id,
    venue_group_classes_id,
    venue_group_classes_price,
    type = "single", // Domyślnie "single" dla kompatybilności z rezerwacją kortów
    provider,
    regulation_acceptance_id,
    blik_code
  ) {
    const response = await api.post(`/reservation`, {
      source: "marketplace",
      type: type,
      venue_id: venueId,
      venue_sport_object_id: venueSportObjectId,
      start_time: startTime,
      end_time: endTime,
      discount_cards: discountCards,
      app_payment_methods_id: appPaymentMethodsId,
      price: reservation_calculated_price,
      phone_number: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      email: eMail,
      user_venue_carnet_id,
      online_payment_method_id,
      provider,
      regulation_acceptance_id,
      blik_code,
      // Opcjonalne parametry dla rezerwacji zajęć fitness
      venue_group_classes_id: venue_group_classes_id || 0,
      venue_group_classes_price: venue_group_classes_price || 0,
      price_list_id: priceListId || 0,
    });
    return response.data;
  },

  async buyCarnet(
    carnetTypeId,
    venueId,
    online_payment_method_id,
    provider,
    regulation_acceptance_id,
    blik_code
  ) {
    const response = await api.post(`/user_venue_carnet`, {
      venue_carnet_type_id: carnetTypeId,
      venue_id: venueId,
      online_payment_method_id,
      provider,
      regulation_acceptance_id,
      blik_code,
    });
    return response.data;
  },

  async getSingleReservation(reservationId, venueId) {
    const response = await api.get(`/reservation/single/${reservationId}`, {
      params: {
        venue_id: venueId,
        reservation_id: reservationId,
      },
    });

    return response.data;
  },

  async createBulkPayment({
    reservation_id,
    venue_id,
    app_payment_methods_id,
    online_payment_method_id,
    provider,
    regulation_acceptance_id,
    blik_code,
  }) {
    const response = await api.post(`/payment/reservation/bulk`, {
      reservation_id,
      venue_id,
      app_payment_methods_id,
      online_payment_method_id,
      provider,
      regulation_acceptance_id,
      blik_code,
    });
    return response.data;
  },

  async editTrainerProfile(trainerProfileId, data) {
    const response = await api.patch(
      `trainer_venue_profile/${trainerProfileId}`,
      data
    );
    return response.data;
  },

  async getUserCarnetsForFitnessReservation(
    venueId,
    venueSportObjectId,
    startTime,
    endTime
  ) {
    const response = await api.get(
      `/venue/sport_object/venue_carnet/${venueId}`,
      {
        params: {
          venue_id: venueId,
          venue_sport_object_id: venueSportObjectId,
          start_time: startTime,
          end_time: endTime,
        },
      }
    );

    return response.data;
  },

  async createOnlinePayment({
    type,
    venue_id,
    reservation_id,
    user_venue_carnet_id,
    app_payment_methods_id,
    online_payment_method_id,
    provider,
    regulation_acceptance_id,
    blik_code,
  }) {
    const response = await api.post("/payment/create", {
      type,
      venue_id,
      reservation_id,
      user_venue_carnet_id,
      app_payment_methods_id,
      online_payment_method_id,
      provider,
      regulation_acceptance_id,
      blik_code,
    });
    return response.data;
  },

  async openDoor(venueId, command = "state") {
    const response = await api.post(`/automation/door/${venueId}`, {
      venue_id: venueId,
      command,
    });
    return response.data;
  },
});
