import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import { authMiddleware } from "./middleware/auth";

// Import modułów tras
import { userRoutes, defaultRoute } from "./routes/userRoutes";
import { reservationRoutes } from "./routes/reservationRoutes";
import { trainerRoutes } from "./routes/trainerRoutes";
import { carnetRoutes } from "./routes/carnetRoutes";
import { errorRoutes } from "./routes/errorRoutes";
import paymentRoutes from "./routes/paymentRoutes";

// Modyfikujemy domyślną trasę
const modifiedDefaultRoute = {
  ...defaultRoute,
  redirect: (to) => {
    // Jeśli użytkownik jest zalogowany, przekieruj na /your-account
    // W przeciwnym razie przekieruj na /login
    return store.getters["user/isAuthenticated"] ? "/your-account" : "/login";
  },
  meta: { requiresAuth: false },
};

// Połącz wszystkie trasy
const routes = [
  modifiedDefaultRoute,
  ...userRoutes,
  ...reservationRoutes,
  ...trainerRoutes,
  ...carnetRoutes,
  ...errorRoutes,
  ...paymentRoutes,
];

// Używamy BASE_URL z Vite
const base = import.meta.env.BASE_URL || "/";

const router = createRouter({
  history: createWebHistory(base),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// Zastosuj middleware autoryzacji
router.beforeEach(authMiddleware);

// Dodatkowy middleware dla tytułów stron i innych funkcjonalności
router.beforeEach((to, from, next) => {
  // Ustaw tytuł strony
  document.title = to.meta.title || "Playmore — Player";

  // Sprawdź czy to strona błędu
  const isErrorPage = to.matched.some((record) => record.meta.error);
  if (isErrorPage) {
    next();
    return;
  }

  next();
});

// Obsługa błędów routingu
router.onError((error) => {
  console.error("Błąd routingu:", error);
  router.push({ name: "Error500" });
});

export default router;
