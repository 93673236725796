export default {
  title: "Status płatności",
  messages: {
    PENDING: {
      title: "Oczekujemy na Twoją płatność",
      description:
        "Oczekujemy na potwierdzenie płatności. Status jest sprawdzany automatycznie. W razie problemów sprawdź swoją skrzynkę e-mail.",
    },
    SUCCESS: {
      title: "Dziękujemy za płatność!",
      description: "Twoja rezerwacja została potwierdzona",
    },
    VOIDED: {
      title: "Płatność została odrzucona",
      description:
        "Niestety płatność nie mogła zostać zrealizowana. Spróbuj ponownie",
    },
    NOTCONFIRMED: {
      title: "Płatność niepotwierdzona",
      description:
        "Płatność oczekuje na potwierdzenie. Sprawdź status za chwilę",
    },
    PARTIALLY_REFUNDED: {
      title: "Płatność częściowo zwrócona",
      description: "Część kwoty została zwrócona na Twoje konto",
    },
    REFUNDED: {
      title: "Płatność zwrócona",
      description: "Cała kwota została zwrócona na Twoje konto",
    },
    ABANDONED: {
      title: "Płatność nie została dokończona",
      description: "Przekroczono limit czasu na dokonanie płatności",
    },
    CONFIRMED: {
      title: "Płatność potwierdzona",
      description: "Twoja płatność została potwierdzona. Dziękujemy!",
    },
  },
  refreshButton: "Odśwież status",
  errors: {
    fetch: "Nie udało się pobrać statusu płatności",
    invalidId: "Nieprawidłowy identyfikator płatności",
    notFound: "Nie znaleziono płatności o podanym identyfikatorze",
    unauthorized: "Brak uprawnień do wyświetlenia tej płatności",
    serverError: "Wystąpił błąd serwera podczas pobierania statusu płatności",
  },
  noPaymentId: "Brak identyfikatora płatności",
  backToReservation: "Powrót do szczegółów rezerwacji",
};
