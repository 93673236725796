export default {
  switchLanguage: "Change language",
  languages: {
    polish: "Polish",
    english: "English",
    ukrainian: "Ukrainian",
  },
  loading: "Loading...",
  error: "An error occurred",
  buttons: {
    close: "Close",
    cancel: "Cancel",
    continue: "Continue",
    back: "Back",
    today: "Today",
    thisWeek: "This week",
    clearFilters: "Clear filters",
    confirm: "Confirm",
    reserve: "Reserve",
    save: "Save",
  },
  fields: {
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    phone: "Phone",
    password: "Password",
  },
  price: {
    base: "Base price",
    total: "Total price",
    currency: "PLN",
  },
  payment: {
    methods: {
      title: "Payment method",
      onSite: "Pay later",
      pass: "Pass",
      online: "Online payment",
      blik: "BLIK",
      transfer: "Bank transfer",
      paymentInitiation: "Bank transfer",
      select: "Select payment method",
      509: "BLIK",
      106: "Bank transfer",
    },
    serviceFee: {
      title: "Service fee",
      amount: "1.98 PLN",
      tooltip:
        "The fee covers operational costs related to payment processing, including payment operator commissions, administrative costs, and reservation system maintenance.",
    },
    status: {
      paid: "Paid",
      unpaid: "Unpaid",
      pending: "Pending payment",
      pending_online: "Pending online payment",
    },
    required: "Payment required",
    timeLimit:
      "To confirm your reservation, please complete the payment within 30 minutes. After this time, the reservation will be automatically cancelled.",
    proceedToPayment: "Proceed to payment",
    processing: "Processing...",
  },
  errors: {
    loadingFailed: "Failed to load data. Please try again later.",
    savingFailed: "Failed to save changes. Please try again later.",
    generalError: "An error occurred. Please try again later.",
  },
  status: {
    pending: "Pending",
    accepted: "Accepted",
  },
  venue: {
    select: "Select venue",
    all: "All venues",
    address: "Venue address",
  },
  search: {
    placeholder: "Search...",
    byClient: "Search by client...",
    noResults: "No results",
    filters: "Filters",
  },
  date: {
    from: "From",
    to: "To",
  },
  time: {
    from: "From",
    to: "To",
  },
  weekDays: {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  },
  form: {
    required: "This field is required",
    invalid: "Invalid value",
    save: {
      button: "Save",
      loading: "Saving...",
      success: "Saved successfully",
      error: "Error while saving",
    },
  },
  legal: {
    terms: "terms of service",
    privacy: "privacy policy",
    gdpr: "GDPR",
    company: "Playmore",
    copyright: "© {year} Playmore. All rights reserved.",
  },
};
