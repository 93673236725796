const DEFAULT_EXPIRY = 1000; // 1 sekunda

class CacheStore {
  constructor(name, expiryTime = DEFAULT_EXPIRY) {
    this.name = name;
    this.expiryTime = expiryTime;
    this.cache = new Map();
  }

  get(key) {
    const cached = this.cache.get(key);
    if (!cached) return null;

    if (Date.now() - cached.timestamp > this.expiryTime) {
      this.cache.delete(key);
      return null;
    }

    return cached.data;
  }

  set(key, data) {
    this.cache.set(key, {
      data,
      timestamp: Date.now(),
    });
  }

  clear() {
    this.cache.clear();
  }

  delete(key) {
    this.cache.delete(key);
  }
}

// Przechowuje instancje cache'ów
const cacheStores = new Map();

export function useCache(storeName, expiryTime = DEFAULT_EXPIRY) {
  // Pobierz istniejący cache store lub stwórz nowy
  if (!cacheStores.has(storeName)) {
    cacheStores.set(storeName, new CacheStore(storeName, expiryTime));
  }

  const store = cacheStores.get(storeName);

  return {
    // Pobierz dane z cache'u
    getFromCache: (key) => store.get(key),

    // Zapisz dane do cache'u
    setInCache: (key, data) => store.set(key, data),

    // Wyczyść cały cache dla tego store'a
    clearCache: () => store.clear(),

    // Usuń konkretny klucz z cache'u
    deleteFromCache: (key) => store.delete(key),

    // Pobierz dane z cache'u lub wykonaj funkcję pobierającą i zapisz wynik
    getOrFetch: (key, fetchFn) => {
      const cached = store.get(key);
      if (cached !== null) {
        return cached;
      }

      return (async () => {
        const data = await fetchFn();
        store.set(key, data);
        return data;
      })();
    },
  };
}
