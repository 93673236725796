export default {
  profile: {
    navigation: {
      venues: {
        label: "Ваші заклади",
        icon: "🏢",
      },
      account: {
        label: "Обліковий запис",
        icon: "👤",
      },
      levels: {
        label: "Рівні гри",
        icon: "🎮",
      },
    },
  },
  reservations: {
    navigation: {
      single: {
        label: "Одиночні",
        icon: "🎾",
      },
      recurring: {
        label: "Регулярні",
        icon: "🔄",
      },
      single_class: {
        label: "Одиночні заняття",
        icon: "👥",
      },
      recurring_class: {
        label: "Регулярні заняття",
        icon: "👥",
      },
    },
    filters: {
      upcoming: "Майбутні",
      past: "Минулі",
      showCancelled: "Показати скасовані",
    },
    empty: {
      title: "Немає бронювань",
      upcoming: "У вас немає майбутніх бронювань.",
      past: "У вас немає минулих бронювань.",
    },
    sections: {
      single: {
        title: "Одиночні бронювання",
        empty: "Немає одиночних бронювань",
      },
      recurring: {
        title: "Регулярні бронювання",
        empty: "Немає регулярних бронювань",
      },
      single_class: {
        title: "Одиночні заняття",
        empty: "Немає одиночних занять",
      },
      recurring_class: {
        title: "Регулярні заняття",
        empty: "Немає регулярних занять",
      },
    },
    recurring: {
      until: "До",
      paid: "оплачено",
      unpaid: "не оплачено",
      details: {
        info: {
          term: "Термін",
          endDate: "Дата закінчення",
          remainingAmount: "Залишилось оплатити",
          totalAmount: "Загальна сума",
        },
        sections: {
          upcoming: "Майбутні бронювання",
          past: "Минулі бронювання",
        },
        selectAll: "Вибрати всі неоплачені",
        trainer: "Тренер",
        status: {
          cancelled: "Скасовано",
          paid: "Оплачено",
          unpaid: "Не оплачено",
        },
      },
      payment: {
        button: "Оплатити вибрані",
        modal: {
          title: {
            select: "Виберіть спосіб оплати",
            required: "Потрібна оплата",
          },
          info: {
            title: "Бронювання потребують оплати",
            message:
              "Щоб підтвердити бронювання, будь ласка, здійсніть оплату протягом 30 хвилин. Після цього часу бронювання будуть автоматично скасовані.",
          },
          buttons: {
            cancel: "Скасувати",
            continue: "Продовжити",
            payment: "Перейти до оплати",
          },
        },
      },
      legal: {
        text: "Розміщуючи замовлення, Користувач підтверджує, що ознайомився та приймає умови та політику конфіденційності обраного спортивного закладу, а також",
        terms: "умови надання послуг Playmore",
        fee: "(раніше Reservise.com). Згідно з прейскурантом, до кожної транзакції буде додано сервісний збір у розмірі 1.98 PLN брутто за обробку електронних платежів.",
      },
    },
    details: {
      loading: "Завантаження деталей бронювання...",
      status: {
        active: "Бронювання активне",
        cancelled: "Бронювання скасовано",
        cancelled_not_on_time: "Бронювання скасовано не вчасно",
      },
      payment: {
        pending: {
          title: "Очікує оплати",
          message: "Не забудьте здійснити оплату перед початком бронювання.",
          serviceFee:
            "До онлайн-оплати буде додано сервісний збір у розмірі 1.98 PLN.",
          button: "Оплатити {price} PLN",
        },
        completed: {
          title: "Бронювання оплачено",
          message: "Оплату здійснено. Ви можете користуватися бронюванням.",
        },
      },
      info: {
        title: "Деталі бронювання",
        dateTime: "Дата та час",
        court: "Корт",
        prices: {
          base: "Базова ціна",
          discountCards: "Знижкові картки ({count})",
          serviceFee: "Сервісний збір",
          total: "Загальна ціна",
        },
      },
      cancellation: {
        title: "Скасувати бронювання",
        free: {
          message:
            "Ви можете скасувати це бронювання без додаткової оплати відповідно до правил закладу. Безкоштовне скасування доступне до {hours} годин до початку.",
          button: "Скасувати бронювання",
        },
        paid: {
          message:
            "Скасування бронювання менш ніж за {hours} годин до початку може призвести до додаткових витрат відповідно до правил закладу.",
          button: "Скасувати бронювання (не вчасно)",
        },
        modal: {
          title: {
            free: "Скасування бронювання",
            paid: "Скасування бронювання не вчасно",
          },
          message: {
            free: "Ви впевнені, що хочете скасувати це бронювання?",
            paid: "Скасування бронювання після терміну може призвести до додаткових витрат. Бажаєте продовжити?",
          },
          buttons: {
            cancel: "Скасувати",
            confirm: "Підтвердити",
          },
        },
      },
      buttons: {
        backToList: "Повернутися до списку бронювань",
        bookAgain: "Забронювати знову",
      },
      payment_modal: {
        title: {
          select: "Виберіть спосіб оплати",
          required: "Потрібна оплата",
        },
        info: {
          title: "Бронювання потребує оплати",
          message:
            "Щоб підтвердити бронювання, будь ласка, здійсніть оплату протягом 30 хвилин. Після цього часу бронювання буде автоматично скасовано.",
        },
        buttons: {
          cancel: "Скасувати",
          continue: "Продовжити",
          payment: "Перейти до оплати",
        },
      },
    },
    tile: {
      cancellation: {
        not_on_time: "Скасовано не вчасно",
      },
      separator: "•",
    },
    loading: "Завантаження бронювань...",
  },
  carnets: {
    title: "Ваші абонементи",
    loading: "Завантаження абонементів...",
    empty: {
      title: "Немає абонементів",
      message:
        "У вас ще немає абонементів. Перегляньте нашу пропозицію та придбайте свій перший абонемент!",
    },
    card: {
      status: {
        available: "Доступний",
        unavailable: "Недоступний",
      },
      dates: {
        added: "Дата додавання",
        expiration: "Дата закінчення",
        noDate: "Немає дати",
      },
      value: {
        remaining: "Залишок",
        total: "Загальна вартість",
      },
      price: "Ціна",
      usage: {
        history: "Історія використання",
        empty: "Немає історії використання",
      },
    },
  },
};
